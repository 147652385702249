@import '@assets/scss/variables.scss';
@import '@assets/scss/mixins.scss';

.searchGroup {
    .searchControl { padding-left: 44px; padding-right: 10px; }
}
.searchIcon { left: 12px; 
    svg { width: 28px; height: 28px; }
    path { fill: $theme-color; }
}
/***** Responsive CSS Start ******/
