@import '@assets/scss/variables.scss';
@import '@assets/scss/mixins.scss';

.formControl {
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    appearance: none;
}
/***** Responsive CSS Start ******/

@media (max-width: 1399px) {
 
}

@media (max-width: 1199px) {
}