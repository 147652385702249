@import "@assets/scss/variables.scss";
@import "@assets/scss/mixins.scss";

.item {
    flex: 1;
}
.row + .row {
    border-top: 1px solid var(--border-medium);
}
.value {
    background: var(--theme-bg);
    &.active {
        background: $theme-color;
        color: $light-color;
    }
}

/***** Responsive CSS Start ******/

@media (max-width: 575px) {
}
