@import '@assets/scss/variables.scss';
@import '@assets/scss/mixins.scss';

.icon {
  width: 30px;
  
}

.arrow {
  width: 25px;
  // height: 25px;
  right: -1px;
  @include filter(brightness(0) invert(1));
}

.text {
  color: var(--font-light);
}
